import React, {FC, Suspense} from 'react';
import './App.scss';
import {BillingSummary} from "./billing";
import {TappTree} from "./tapptree";
import {PendingAffiliates} from "./affiliates";
import {DashboardBundle} from "@utils/dashboard-bundle";

declare global {
    interface Window {
        tapp: {
            debug?: boolean;
            component?: string;
            user: { id: number, name: string, email: string };
            gqlToken: string;
            gqlUrl: string;
            relatedAffiliates?: number[];
            offers?: {id: number, type: 'group' | 'offer', label: string}|[];
            domains: string[];
            affiliateId?: number | null;
            adminAccess: boolean;
            tiktokAccess: boolean;
            salesAccess: boolean;
            limitedAccess: boolean;
            adsAccess: boolean;
        }
    }
}

const Components: Record<string, FC> = {
    'billing/unpaid': BillingSummary,
    'landing/tapptree.io': TappTree,
    'landing/tappwall.co': TappTree,
    'wall': TappTree,
    'landing/tapptree.co': TappTree,
    'pending_affiliates': PendingAffiliates,
}


const App = function App() {
    const tapp = window.tapp;

    let Component;
    if (tapp != null && tapp.component != null && Components[tapp.component] != null)
        Component = Components[tapp.component]
    else {
        // remove admin prefix & trailing slash
        const route = window.location.pathname.slice(8, window.location.pathname.length - 1);
        // identify correct component chunk to load
        Component = Components[route];
        if (!Component) {
            // use unique production domain or key in dynamic path to identify component
            if (route.includes('tapptree') || window.location.host.includes('tapptree') || window.location.host.includes('tappwall'))
                Component = TappTree;
        }
    }
    return <div className="container-fluid container-react">
        <Suspense fallback={<div>Loading...</div>}>
            {!Component ? <DashboardBundle/> : <Component/>}
        </Suspense>
    </div>;
}

export default App;
